import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./features/login/login.component";
import {authorizedCanActivate, unAuthorizedCanActivate} from "./guards/auth-guard.service";
import {HomeComponent} from "./features/home/home.component";
import {SalesInvoicesComponent} from "./features/sales-invoices/sales-invoices.component";
import {PurchaseInvoicesComponent} from "./features/purchase-invoices/purchase-invoices.component";
import {CreateSalesInvoiceComponent} from "./features/create-sales-invoice/create-sales-invoice.component";
import {ViewSalesInvoiceComponent} from "./features/view-sales-invoice/view-sales-invoice.component";

const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
    canActivate: [unAuthorizedCanActivate]
  },
  {
    path: "sales-invoices",
    component: SalesInvoicesComponent,
    canActivate: [authorizedCanActivate]
  },
  {
    path: "sales-invoices/:id",
    component: ViewSalesInvoiceComponent,
    canActivate: [authorizedCanActivate]
  },
  {
    path: "create-sales-invoices",
    component: CreateSalesInvoiceComponent,
    canActivate: [authorizedCanActivate]
  },
  {
    path: "purchase-invoices",
    component: PurchaseInvoicesComponent,
    canActivate: [authorizedCanActivate]
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [authorizedCanActivate]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
