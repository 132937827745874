import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { BankService } from './api/bank.service';
import { CustomerService } from './api/customer.service';
import { CustomerInvoiceService } from './api/customerInvoice.service';
import { DashboardService } from './api/dashboard.service';
import { ExportService } from './api/export.service';
import { InvoiceDocumentService } from './api/invoiceDocument.service';
import { PurchaseInvoiceService } from './api/purchaseInvoice.service';
import { SalesInvoiceService } from './api/salesInvoice.service';
import { UserService } from './api/user.service';
import { VendorService } from './api/vendor.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    BankService,
    CustomerService,
    CustomerInvoiceService,
    DashboardService,
    ExportService,
    InvoiceDocumentService,
    PurchaseInvoiceService,
    SalesInvoiceService,
    UserService,
    VendorService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
