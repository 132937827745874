/**
 * BlueBear.Invoicing.WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BlueBearInvoicingPersistanceDataModelsAccountType } from './blueBearInvoicingPersistanceDataModelsAccountType';
import { BlueBearInvoicingPersistanceDataModelsDataPendingPurchaseInvoice } from './blueBearInvoicingPersistanceDataModelsDataPendingPurchaseInvoice';
import { BlueBearInvoicingPersistanceDataModelsDataSalesInvoice } from './blueBearInvoicingPersistanceDataModelsDataSalesInvoice';
import { BlueBearInvoicingPersistanceDataModelsExpenseType } from './blueBearInvoicingPersistanceDataModelsExpenseType';
import { BlueBearInvoicingPersistanceDataModelsTransactionType } from './blueBearInvoicingPersistanceDataModelsTransactionType';

export interface BlueBearInvoicingPersistanceDataModelsDataBankTransaction { 
    id?: number;
    account?: string;
    date?: string;
    counterPartyName?: string;
    counterPartyAccount?: string;
    amount?: number;
    transactionType?: BlueBearInvoicingPersistanceDataModelsTransactionType;
    statement?: string;
    personalExpense?: boolean;
    expenseType?: BlueBearInvoicingPersistanceDataModelsExpenseType;
    salesInvoices?: Array<BlueBearInvoicingPersistanceDataModelsDataSalesInvoice>;
    purchaseInvoices?: Array<BlueBearInvoicingPersistanceDataModelsDataPendingPurchaseInvoice>;
    accountType?: BlueBearInvoicingPersistanceDataModelsAccountType;
}