import {Component, OnInit} from '@angular/core';
import {SalesInvoicesService} from "../../serivces/sales-invoices.service";

@Component({
  selector: 'app-sales-invoices',
  templateUrl: './sales-invoices.component.html',
  styleUrls: ['./sales-invoices.component.scss']
})
export class SalesInvoicesComponent implements OnInit {

  currentSalesInvoices$ = this.salesInvoice.salesInvoices$;

  constructor(private salesInvoice: SalesInvoicesService) {

  }

  ngOnInit(): void {
    this.salesInvoice.getSalesInvoices();
  }
}
