import { Component, OnInit } from '@angular/core';
import {CurrencyPipe, DatePipe, NgIf} from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import { Router } from "@angular/router";
import { PurchaseInvoicesService } from "../../serivces/purchase-invoices.service";
import { DragAndDropDirective } from "../../directives/drag-and-drop.directive";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { SpinnerService } from "../../serivces/spinner.service";
import { MatCardModule } from "@angular/material/card";
import { MatListModule } from "@angular/material/list";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {MatNativeDateModule, MatOption, provideNativeDateAdapter} from "@angular/material/core";
import {FormsModule} from "@angular/forms";
import '../../shared/extensions/datetime.extensions';
import {MatSelect} from "@angular/material/select";

@Component({
  selector: 'app-purchase-invoices',
  standalone: true,
  imports: [
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    DragAndDropDirective,
    MatPaginatorModule,
    MatCardModule,
    MatListModule,
    MatTableModule,
    CurrencyPipe,
    DatePipe,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatSelect,
    MatOption,
    NgIf,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './purchase-invoices.component.html',
  styleUrls: ['./purchase-invoices.component.scss']
})
export class PurchaseInvoicesComponent implements OnInit {
  showUploadInvoice = false;
  uploadingInvoice = false;
  files: File[] = [];
  pageNumber = 1;
  totalItems = this.purchaseInvoiceService.purchaseInvoicesTotalItems$;
  currentPurchasesInvoices = this.purchaseInvoiceService.purchaseInvoices$;
  pageSize = 10;
  displayedColumns: string[] = ['vendorName', 'invoiceTotal', 'invoiceDate', 'paymentLinked', 'actions'];
  fromDate: Date | null = null;
  toDate: Date | null = null;
  paymentLinked: boolean | undefined = undefined;

  constructor(private purchaseInvoiceService: PurchaseInvoicesService, private loaderService: SpinnerService, private router: Router) {}

  ngOnInit() {
    this.loaderService.show();
    this.refreshData();
  }

  refreshData() {
    this.purchaseInvoiceService.getPurchaseInvoices(this.pageNumber, this.pageSize);
  }

  uploadPurchaseInvoices() {
    this.uploadingInvoice = true;
    for (let file of Array.from(this.files)) {
      this.purchaseInvoiceService.uploadNewPurchaseInvoice(file).subscribe(() => {
        this.refreshData();
        this.uploadingInvoice = false;
      });
    }
    this.files = [];
    this.showUploadInvoice = false;
  }

  onFileChange(fileList: FileList) {
    if (fileList && fileList.length !== 0) {
      for (let i = 0; i < fileList.length; i++) {
        this.files.push(fileList[i]);
      }
    }
  }

  removePurchaseInvoice(purchaseInvoiceId: number) {
    this.purchaseInvoiceService.removePurchaseInvoice(purchaseInvoiceId).subscribe(() => {
      this.refreshData();
    });
  }

  onFileSelected(event: Event) {
    this.files = Array.from((event!.target as HTMLInputElement).files!);
  }

  pageChanged($event: PageEvent) {
    this.pageNumber = $event.pageIndex + 1;
    this.pageSize = $event.pageSize;
    this.refreshData();
  }

  viewInvoice(id: number) {
    this.router.navigate(['purchase-invoices', id]);
  }

  filterPurchaseInvoices() {
    this.purchaseInvoiceService.getPurchaseInvoices(this.pageNumber, this.pageSize, undefined, this.fromDate?.toDateOnlyString(), this.toDate?.toDateOnlyString(), undefined, undefined, this.paymentLinked);
  }
}
