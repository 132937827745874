import {Component, OnInit} from '@angular/core';
import {DecimalPipe} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatToolbarModule} from "@angular/material/toolbar";
import {RouterLink} from "@angular/router";
import {PurchaseInvoicesService} from "../../serivces/purchase-invoices.service";
import {DragAndDropDirective} from "../../directives/drag-and-drop.directive";
import {MatPaginatorModule, PageEvent} from "@angular/material/paginator";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-purchase-invoices',
  standalone: true,
  imports: [
    DecimalPipe,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    RouterLink,
    DragAndDropDirective,
    MatPaginatorModule,
    MatProgressSpinner
  ],
  templateUrl: './purchase-invoices.component.html',
  styleUrl: './purchase-invoices.component.scss'
})
export class PurchaseInvoicesComponent implements OnInit {

  showUploadInvoice = false
  uploadingInvoice = false
  files: File[] = [];
  pageNumber = 1;
  totalPages = this.purchaseInvoiceService.purchaseInvoicesPages$;
  totalItems = this.purchaseInvoiceService.purchaseInvoicesTotalItems$;
  currentPurchasesInvoices = this.purchaseInvoiceService.purchaseInvoices$;
  pageSize = 10;

  constructor(private purchaseInvoiceService: PurchaseInvoicesService) {

  }

  ngOnInit() {
    this.refreshData();
  }

  refreshData() {
    this.purchaseInvoiceService.getPurchaseInvoices(this.pageNumber, this.pageSize);
  }

  uploadPurchaseInvoices() {
    this.uploadingInvoice = true;
    for (let file of Array.from(this.files)) {
      this.purchaseInvoiceService.uploadNewPurchaseInvoice(file).subscribe(() => {
        this.refreshData();
        this.uploadingInvoice = false;
      });
    }
    this.files = [];
    this.showUploadInvoice = false;
  }

  onFileChange(fileList: FileList) {
    if (fileList && fileList.length !== 0) {
      for (let i = 0; i < fileList.length; i++) {
        this.files.push(fileList[i]);
      }
    }
  }

  removePurchaseInvoice(purchaseInvoiceId: number) {
    this.purchaseInvoiceService.removePurchaseInvoice(purchaseInvoiceId).subscribe(() => {
      this.refreshData();
    });
  }

  onFileSelected(event: Event) {
    this.files = Array.from((event!.target as HTMLInputElement).files!);
  }

  pageChanged($event: PageEvent) {
    this.pageNumber = $event.pageIndex + 1;
    this.pageSize = $event.pageSize;
    this.refreshData();
  }

  viewInvoice(id: number) {
    this.purchaseInvoiceService.getPurchaseInvoicePDF(id);
  }
}
