import { Observable } from "rxjs";
import {
  BlueBearInvoicingBusinessInvoicesInvoicesCreateInvoiceCommand,
  BlueBearInvoicingBusinessInvoicesInvoicesListInvoicesInvoiceListItem, InvoiceDocumentService, InvoiceServiceInvoice,
  SalesInvoiceService
} from "../../clients";
import {Injectable, signal} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SalesInvoicesService {

  readonly salesInvoices$$ = signal<BlueBearInvoicingBusinessInvoicesInvoicesListInvoicesInvoiceListItem[]>([]);
  salesInvoices$ = this.salesInvoices$$.asReadonly();

  readonly salesInvoice$$ = signal<InvoiceServiceInvoice | null>(null);
  salesInvoice$ = this.salesInvoice$$.asReadonly();

  constructor(private salesService: SalesInvoiceService, private invoiceDocumentService: InvoiceDocumentService) {
  }

  getSalesInvoices(){
    this.salesService.apiSalesInvoicesGet().subscribe((data) => {
     this.salesInvoices$$.set(data);
    });
  }

  createSalesInvoice(salesInvoice: BlueBearInvoicingBusinessInvoicesInvoicesCreateInvoiceCommand): Observable<any>{
    return this.salesService.apiSalesInvoicesPost(salesInvoice);
  }

  getSalesInvoice(salesInvoiceId: number) {
    this.salesService.apiSalesInvoicesInvoiceIdGet(salesInvoiceId).subscribe((data) => {
      this.salesInvoice$$.set(data);
    });
  }

  getSalesInvoicePDF(salesInvoiceId: number) {
    this.invoiceDocumentService.apiSalesInvoicesIdDocumentGet(salesInvoiceId).subscribe((data) => {
      const blob = new Blob([data], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
}
