import {Component, OnInit} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatToolbarModule} from "@angular/material/toolbar";
import {RouterLink} from "@angular/router";
import {LoginService} from "../../serivces/login.service";
import {CustomersServices} from "../../serivces/customers.services";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {BlueBearInvoicingBusinessInvoicesCustomersCreateCustomerCustomer} from "../../../clients";
import {FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AutoCompleteCompleteEvent, AutoCompleteModule} from "primeng/autocomplete";
import {InputTextModule} from "primeng/inputtext";
import {Button} from "primeng/button";
import {CalendarModule} from "primeng/calendar";
import {SalesInvoicesService} from "../../serivces/sales-invoices.service";

@Component({
  selector: 'app-create-sales-invoice',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    RouterLink,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    InputTextModule,
    Button,
    CalendarModule
  ],
  templateUrl: './create-sales-invoice.component.html',
  styleUrl: './create-sales-invoice.component.scss'
})
export class CreateSalesInvoiceComponent implements OnInit {

  customers$ = this.customerService.customers$;
  filteredSuggestions = this.customers$();
  createSalesInvoiceFormGroup: FormGroup;

  constructor(private loginService: LoginService, private customerService: CustomersServices, private salesInvoiceService: SalesInvoicesService, private fb: FormBuilder){
    this.createSalesInvoiceFormGroup = this.fb.group({
      customer: new FormControl(),
      date: new FormControl(new Date()),
      items: this.fb.array([this.createItem()])
    });
  }

  get items(): FormArray {
    return this.createSalesInvoiceFormGroup.get('items') as FormArray;
  }

  addItem(): void {
    this.items.push(this.createItem());
  }

  removeItem(index: number): void {
    this.items.removeAt(index);
  }

  createItem(): FormGroup {
    return this.fb.group({
      description: ['', Validators.required],
      count: [0.0, Validators.required],
      price: [0.0, Validators.required]
    });
  }

  ngOnInit() {
    this.customerService.GetCustomers();
  }

  logout() {
    this.loginService.logout();
  }

  search($event: AutoCompleteCompleteEvent) {
    this.filteredSuggestions = this.customers$().filter((customer: BlueBearInvoicingBusinessInvoicesCustomersCreateCustomerCustomer) => {
      return customer.name!.toLowerCase().includes($event.query.toLowerCase());
    });
  }

  createInvoice() {
    const invoiceDate = this.createSalesInvoiceFormGroup.get('date')?.value;
    const invoiceDay = invoiceDate.getDate().toString().padStart(2, "0");
    const invoiceMonth = (invoiceDate.getMonth() + 1).toString().padStart(2, "0");
    this.salesInvoiceService.createSalesInvoice({
      customerId: this.createSalesInvoiceFormGroup.get('customer')?.value.id,
      items: this.createSalesInvoiceFormGroup.get('items')?.value,
      invoiceDate: `${invoiceDate.getFullYear()}-${invoiceMonth}-${invoiceDay}`,
      intraCommunity: false
    });
  }
}
