import {Injectable, signal} from "@angular/core";
import {BlueBearInvoicingBusinessInvoicesCustomersCreateCustomerCustomer, CustomerService} from "../../clients";

@Injectable({providedIn: 'root'})
export class CustomersServices {

    readonly customers$$ = signal<BlueBearInvoicingBusinessInvoicesCustomersCreateCustomerCustomer[]>([]);
    customers$ = this.customers$$.asReadonly();

    constructor(private customerApiService: CustomerService) {}

    public GetCustomers() {
        return this.customerApiService.apiCustomersGet().subscribe((data) => {
            this.customers$$.set(data);
        });
    }

    public SetCustomers(filteredCustomers: BlueBearInvoicingBusinessInvoicesCustomersCreateCustomerCustomer[]) {
      this.customers$$.set(filteredCustomers);
    }
}
