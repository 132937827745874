<div class="container">
  <!-- Button to upload new purchase invoice -->
  <button class="upload-button" mat-raised-button color="primary" (click)="showUploadInvoice = !showUploadInvoice">
    Upload new purchase invoice
  </button>

  @if (showUploadInvoice) {
    <mat-card class="upload-form">
      <mat-card-content>
        <div class="dropzone" appDragAndDrop (fileDropped)="onFileChange($event)">
          <input (change)="onFileSelected($event)" type="file" multiple id="fileDropRef"/>
          <mat-icon>cloud_upload</mat-icon>
          <h3>Drag and drop files here to upload</h3>
          <h3>or</h3>
          <label for="fileDropRef">Select files</label>
          <mat-list>
            <mat-list-item *ngFor="let file of files">
              <mat-icon matListIcon>insert_drive_file</mat-icon>
              <h4 matLine>{{ file.name }}</h4>
            </mat-list-item>
          </mat-list>
        </div>
        <button mat-raised-button color="primary" (click)="uploadPurchaseInvoices()">Upload</button>
      </mat-card-content>
    </mat-card>
  }

  <!-- Filter purchase invoices -->
  <div class="invoice-filters">
    <h3>Filters</h3>
    <div class="filter-fields">
      <mat-form-field appearance="outline" hideRequiredMarker>
        <mat-label>Vanaf</mat-label>
        <input matInput [matDatepicker]="fromPicker" [(ngModel)]="fromDate" name="fromDate">
        <mat-datepicker-toggle matIconSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" hideRequiredMarker>
        <mat-label>Tot</mat-label>
        <input matInput [matDatepicker]="toPicker" [(ngModel)]="toDate" name="toDate">
        <mat-datepicker-toggle matIconSuffix [for]="toPicker"></mat-datepicker-toggle>
        <mat-datepicker #toPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" hideRequiredMarker>
        <mat-label>Betaling</mat-label>
        <mat-select [(ngModel)]="paymentLinked">
          <mat-option [value]="true">Ja</mat-option>
          <mat-option [value]="false">Nee</mat-option>
          <mat-option [value]="undefined">Alles</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="filterPurchaseInvoices()">Filter</button>
    </div>
  </div>

  <!-- List of purchase invoices -->
  <div class="invoices-list">
    <table mat-table [dataSource]="currentPurchasesInvoices()" class="mat-elevation-z8">
      <ng-container matColumnDef="vendorName">
        <th mat-header-cell *matHeaderCellDef>Leverancier</th>
        <td mat-cell *matCellDef="let element">{{ element.vendorName }}</td>
      </ng-container>

      <ng-container matColumnDef="invoiceTotal">
        <th mat-header-cell *matHeaderCellDef>Factuur totaal</th>
        <td mat-cell *matCellDef="let element">{{ element.grandTotal | currency:'EUR':'symbol':'1.2-2' }}</td>
      </ng-container>

      <ng-container matColumnDef="invoiceDate">
        <th mat-header-cell *matHeaderCellDef>Datum</th>
        <td mat-cell *matCellDef="let element">{{ element.invoiceDate | date:'dd/MM/yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="paymentLinked">
        <th mat-header-cell *matHeaderCellDef>Betaling</th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox [checked]="element.linked" disabled></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Acties</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="removePurchaseInvoice(element.id)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="viewInvoice(row.id)"></tr>
    </table>
    <mat-paginator (page)="pageChanged($event)"
                   [length]="totalItems()"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"
                   aria-label="Select page">
    </mat-paginator>
  </div>
</div>
