import {Component} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {PurchaseInvoicesService} from "../../serivces/purchase-invoices.service";
import {ActivatedRoute} from "@angular/router";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {provideNativeDateAdapter} from "@angular/material/core";
import {MatInput} from "@angular/material/input";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-view-purchase-invoice',
  standalone: true,
  imports: [
    MatButton,
    MatCard,
    MatCardActions,
    MatCardContent,
    MatCardHeader,
    MatCardTitle,
    MatFormField,
    MatDatepickerInput,
    MatDatepicker,
    MatDatepickerToggle,
    MatLabel,
    MatInput,
    FormsModule
  ],
  providers: [
    provideNativeDateAdapter(),
  ],
  templateUrl: './view-purchase-invoice.component.html',
  styleUrl: './view-purchase-invoice.component.scss'
})
export class ViewPurchaseInvoiceComponent {

  currentPurchaseInvoice$ = this.purchaseInvoiceService.purchaseInvoice$;

  constructor(private readonly purchaseInvoiceService: PurchaseInvoicesService, private readonly activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(({id}) => {
      this.purchaseInvoiceService.getPurhchaseInvoice(id);
    });
  }

  viewInvoice(id: number) {
    this.purchaseInvoiceService.getPurchaseInvoicePDF(id);
  }

  saveChanges() {
    this.purchaseInvoiceService.updatePurchaseInvoice(this.currentPurchaseInvoice$()).subscribe(() => {
      this.purchaseInvoiceService.getPurhchaseInvoice(this.currentPurchaseInvoice$()!.id!);
    });
  }

  isGrossAmountValid(): boolean {
    const invoice = this.currentPurchaseInvoice$();
    if (!invoice) return true;

    const calculatedGross = (invoice.netAmount || 0) + (invoice.vatAmount || 0);
    return Math.abs(calculatedGross - (invoice.grossAmount || 0)) < 0.01;
  }
}
