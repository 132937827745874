/**
 * BlueBear.Invoicing.WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InvoiceServiceInvoiceItem } from './invoiceServiceInvoiceItem';

export interface BlueBearInvoicingBusinessInvoicesInvoicesCreateInvoiceCommand {
    customerId?: number;
    items?: Array<InvoiceServiceInvoiceItem>;
    invoiceDate?: string;
    intraCommunity?: boolean;
}
