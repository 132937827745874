/**
 * BlueBear.Invoicing.WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BlueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoice } from '../model/blueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoice';
import { BlueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoiceListItem } from '../model/blueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoiceListItem';
import { BlueBearInvoicingBusinessInvoicesInvoicesPurchasesUpdateInvoiceUpdateInvoiceCommand } from '../model/blueBearInvoicingBusinessInvoicesInvoicesPurchasesUpdateInvoiceUpdateInvoiceCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PurchaseInvoiceService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPurchasesInvoicesDocumentPostForm(file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPurchasesInvoicesDocumentPostForm(file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPurchasesInvoicesDocumentPostForm(file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPurchasesInvoicesDocumentPostForm(file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/purchases/invoices/document`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pageNumber 
     * @param pageSize 
     * @param beginDate 
     * @param endDate 
     * @param minAmount 
     * @param maxAmount 
     * @param customerId 
     * @param linked 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPurchasesInvoicesGet(pageNumber?: number, pageSize?: number, beginDate?: string, endDate?: string, minAmount?: number, maxAmount?: number, customerId?: number, linked?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<BlueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoiceListItem>>;
    public apiPurchasesInvoicesGet(pageNumber?: number, pageSize?: number, beginDate?: string, endDate?: string, minAmount?: number, maxAmount?: number, customerId?: number, linked?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BlueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoiceListItem>>>;
    public apiPurchasesInvoicesGet(pageNumber?: number, pageSize?: number, beginDate?: string, endDate?: string, minAmount?: number, maxAmount?: number, customerId?: number, linked?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BlueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoiceListItem>>>;
    public apiPurchasesInvoicesGet(pageNumber?: number, pageSize?: number, beginDate?: string, endDate?: string, minAmount?: number, maxAmount?: number, customerId?: number, linked?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (beginDate !== undefined && beginDate !== null) {
            queryParameters = queryParameters.set('BeginDate', <any>beginDate);
        }
        if (endDate !== undefined && endDate !== null) {
            queryParameters = queryParameters.set('EndDate', <any>endDate);
        }
        if (minAmount !== undefined && minAmount !== null) {
            queryParameters = queryParameters.set('MinAmount', <any>minAmount);
        }
        if (maxAmount !== undefined && maxAmount !== null) {
            queryParameters = queryParameters.set('MaxAmount', <any>maxAmount);
        }
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('CustomerId', <any>customerId);
        }
        if (linked !== undefined && linked !== null) {
            queryParameters = queryParameters.set('Linked', <any>linked);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<BlueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoiceListItem>>('get',`${this.basePath}/api/purchases/invoices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPurchasesInvoicesIdDocumentGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiPurchasesInvoicesIdDocumentGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiPurchasesInvoicesIdDocumentGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiPurchasesInvoicesIdDocumentGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiPurchasesInvoicesIdDocumentGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request('get',`${this.basePath}/api/purchases/invoices/${encodeURIComponent(String(id))}/document`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param invoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPurchasesInvoicesInvoiceIdApprovePatch(invoiceId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPurchasesInvoicesInvoiceIdApprovePatch(invoiceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPurchasesInvoicesInvoiceIdApprovePatch(invoiceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPurchasesInvoicesInvoiceIdApprovePatch(invoiceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling apiPurchasesInvoicesInvoiceIdApprovePatch.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('patch',`${this.basePath}/api/purchases/invoices/${encodeURIComponent(String(invoiceId))}/approve`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param invoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPurchasesInvoicesInvoiceIdDelete(invoiceId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPurchasesInvoicesInvoiceIdDelete(invoiceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPurchasesInvoicesInvoiceIdDelete(invoiceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPurchasesInvoicesInvoiceIdDelete(invoiceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling apiPurchasesInvoicesInvoiceIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/purchases/invoices/${encodeURIComponent(String(invoiceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param invoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPurchasesInvoicesInvoiceIdGet(invoiceId: number, observe?: 'body', reportProgress?: boolean): Observable<BlueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoice>;
    public apiPurchasesInvoicesInvoiceIdGet(invoiceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BlueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoice>>;
    public apiPurchasesInvoicesInvoiceIdGet(invoiceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BlueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoice>>;
    public apiPurchasesInvoicesInvoiceIdGet(invoiceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling apiPurchasesInvoicesInvoiceIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BlueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoice>('get',`${this.basePath}/api/purchases/invoices/${encodeURIComponent(String(invoiceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param invoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPurchasesInvoicesInvoiceIdPaidPatch(invoiceId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPurchasesInvoicesInvoiceIdPaidPatch(invoiceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPurchasesInvoicesInvoiceIdPaidPatch(invoiceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPurchasesInvoicesInvoiceIdPaidPatch(invoiceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling apiPurchasesInvoicesInvoiceIdPaidPatch.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('patch',`${this.basePath}/api/purchases/invoices/${encodeURIComponent(String(invoiceId))}/paid`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param invoiceId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPurchasesInvoicesInvoiceIdPut(invoiceId: number, body?: BlueBearInvoicingBusinessInvoicesInvoicesPurchasesUpdateInvoiceUpdateInvoiceCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPurchasesInvoicesInvoiceIdPut(invoiceId: number, body?: BlueBearInvoicingBusinessInvoicesInvoicesPurchasesUpdateInvoiceUpdateInvoiceCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPurchasesInvoicesInvoiceIdPut(invoiceId: number, body?: BlueBearInvoicingBusinessInvoicesInvoicesPurchasesUpdateInvoiceUpdateInvoiceCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPurchasesInvoicesInvoiceIdPut(invoiceId: number, body?: BlueBearInvoicingBusinessInvoicesInvoicesPurchasesUpdateInvoiceUpdateInvoiceCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling apiPurchasesInvoicesInvoiceIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/purchases/invoices/${encodeURIComponent(String(invoiceId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
