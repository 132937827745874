import {Injectable, signal} from "@angular/core";
import {Observable} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {
  BankService, BlueBearInvoicingBusinessBankBankTransactionDetailBankTransactionDetail,
  BlueBearInvoicingBusinessBankUploadBankStatementBankTransaction, BlueBearInvoicingPersistanceDataModelsExpenseType,
} from "../../clients";

@Injectable({providedIn: 'root'})

export class BankingService {

  constructor(private readonly bankService: BankService) {
  }


  readonly transactions$$ = signal<BlueBearInvoicingBusinessBankUploadBankStatementBankTransaction[]>([]);
  transactions$ = this.transactions$$.asReadonly();

  readonly transactionsTotalItems$$ = signal<number>(1);
  transactionsTotalItems$ = this.transactionsTotalItems$$.asReadonly();

  readonly transactionDetails$$ = signal<BlueBearInvoicingBusinessBankBankTransactionDetailBankTransactionDetail | undefined>(undefined);
  transactionDetails$ = this.transactionDetails$$.asReadonly();

  getTransactions(pageNumber: number, pageSize: number, beginDate?: Date, endDate?: Date, linked?: boolean) {

    this.bankService.apiBankTransactionsGet(pageNumber, pageSize, beginDate?.toDateOnlyString(), endDate?.toDateOnlyString(), linked, 'response', false)
      .subscribe((data) => {
        if (data != null) {
          this.transactions$$.set(data.body!);
          let paginationInfo = JSON.parse(data.headers.get('X-Pagination')!);
          this.transactionsTotalItems$$.set(paginationInfo.TotalCount);
        }
      });
  }

  loadTransactionDetails(id: number) {
    if (id != null) {
      this.transactionDetails$$.set(undefined);
      this.bankService.apiBankTransactionsIdGet(id).subscribe((data) => {
        this.transactionDetails$$.set(data);
      });
    }
  }

  updateTransactionWithExpenseType(id: number, expenseType: number) {
    return this.bankService.apiBankTransactionsIdPut(id, {expenseType: expenseType as BlueBearInvoicingPersistanceDataModelsExpenseType});
  }

  uploadBankStatement(file: File) : Observable<HttpResponse<any>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.bankService.apiBankStatementCsvPostForm(file, 'response', false);
  }

  linkTransaction(transactionId: number, invoiceId: number) {
    return this.bankService.apiBankTransactionsIdLinkInvoicePut(transactionId, {purchaseInvoiceId: invoiceId});
  }

  removeLink(id: number, purchaseInvoiceId: number) {
    return this.bankService.apiBankTransactionsIdLinkInvoiceDelete(id, undefined, purchaseInvoiceId);
  }
}
