import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {AuthModule} from "@auth0/auth0-angular";
import {environment} from "../environments/environment";
import {HomeComponent} from './features/home/home.component';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {SalesInvoicesComponent} from './features/sales-invoices/sales-invoices.component';
import {ApiModule, BASE_PATH} from "../clients";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthenticationInterceptor} from "./serivces/authentication.interceptor";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatProgressBarModule} from "@angular/material/progress-bar";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SalesInvoicesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSlideToggleModule,
    BrowserAnimationsModule,
    AuthModule.forRoot({
      domain: environment.auth.domain,
      clientId: environment.auth.clientId,
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: environment.auth.authorizationParams.redirect_uri,
        audience: environment.auth.audience,
      },
      useRefreshTokens: true,
    }),
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    ApiModule,
    HttpClientModule,
    MatExpansionModule,
    MatProgressBarModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
    {provide: BASE_PATH, useValue: environment.apiUrl},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
