export const environment = {
  production: false,
  apiUrl: "https://bearfinance-api-dev.azurewebsites.net",
  auth: {
    domain: 'dev-ff0wid4d.us.auth0.com',
    clientId: 'wTawHQdZrRXSOXn6oZARlIjncNl8YBwO',
    audience: 'https://bearfinance-api-dev.azurewebsites.net/',
    authorizationParams: {
      redirect_uri: 'https://finance-dev.blue-bear.be/home',    },
  },
};
