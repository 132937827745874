import { Component } from '@angular/core';
import {LoginService} from "../../serivces/login.service";
import {SalesInvoicesService} from "../../serivces/sales-invoices.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(private loginService: LoginService, private salesService: SalesInvoicesService) { }

  logout() {
    this.loginService.logout();
  }
}
