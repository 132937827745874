/**
 * BlueBear.Invoicing.WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type BlueBearInvoicingPersistanceDataModelsExpenseType = 0 | 1 | 2 | 3 | 4;

export const BlueBearInvoicingPersistanceDataModelsExpenseType = {
    NUMBER_0: 0 as BlueBearInvoicingPersistanceDataModelsExpenseType,
    NUMBER_1: 1 as BlueBearInvoicingPersistanceDataModelsExpenseType,
    NUMBER_2: 2 as BlueBearInvoicingPersistanceDataModelsExpenseType,
    NUMBER_3: 3 as BlueBearInvoicingPersistanceDataModelsExpenseType,
    NUMBER_4: 4 as BlueBearInvoicingPersistanceDataModelsExpenseType
};