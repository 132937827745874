import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {LoginService} from "../serivces/login.service";
import {map} from "rxjs";


export const authorizedCanActivate: CanActivateFn  = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {

  const loginService = inject(LoginService);
  const router = inject(Router);

  return loginService.isLoggedIn()
    .pipe(
      map(isLoggedIn => {
        if (isLoggedIn) {
          return true;
        } else {
          return router.createUrlTree(['']);
        }
      })
    )
}

export const unAuthorizedCanActivate : CanActivateFn  = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const loginService = inject(LoginService);
  const router = inject(Router);

  return loginService.isLoggedIn()
    .pipe(
      map(isLoggedIn => {
        if (!isLoggedIn) {
          return true;
        } else {
          return router.createUrlTree(['home']);
        }
      })
    )
}
