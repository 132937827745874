import {Component, OnInit} from '@angular/core';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {AuthService} from "@auth0/auth0-angular";
import {Router} from "@angular/router";
import {LoginService} from "../../serivces/login.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    MatSlideToggleModule,
    MatButtonModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule
  ]
})
export class LoginComponent {

  constructor(private loginService: LoginService) {

  }

  logout() {
    this.loginService.logout();
  }

  login() {
    this.loginService.login();
  }
}
