/**
 * BlueBear.Invoicing.WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SystemDateOnly } from './systemDateOnly';

export interface BlueBearInvoicingBusinessInvoicesInvoicesListInvoicesInvoiceListItem { 
    customerName?: string;
    invoiceId?: number;
    invoiceNumber?: string;
    invoiceTotal?: number;
    invoiceVAT?: number;
    invoiceGrandTotal?: number;
    invoiceDate?: SystemDateOnly;
}