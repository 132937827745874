<div class="container">

  <div class="section-header">
    <button mat-button color="primary" (click)="toggleUploadSection()">
      <mat-icon>{{ showUploadSection ? 'expand_less' : 'cloud_upload' }}</mat-icon>
      {{ showUploadSection ? 'Hide upload section' : 'Upload statement' }}
    </button>
  </div>

  @if (showUploadSection) {
    <div class="upload-container">
      <div class="upload-zone"
           (dragover)="onDragOver($event)"
           (dragleave)="onDragLeave($event)"
           (drop)="onDrop($event)"
           [class.drag-over]="isDragOver">
        <div class="upload-content">
          <mat-icon>cloud_upload</mat-icon>
          <h3>Upload bank statement</h3>
          <p>Drag & drop your file here or</p>
          <button mat-raised-button color="primary" (click)="fileInput.click()">Browse files</button>
          <input #fileInput type="file" (change)="onFileSelected($event)" hidden>

          @if (selectedFile) {
            <div class="selected-file-info">
              <p class="file-info">Selected file: {{ selectedFile.name }}</p>
              <div class="upload-actions">
                <button mat-raised-button color="accent" (click)="uploadFile()">
                  Upload file
                </button>
                <button mat-button color="warn" (click)="clearSelectedFile()">
                  Cancel
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  }

  <div class="invoice-filters">
    <h3>Filters</h3>
    <div class="filter-fields">
      <mat-form-field appearance="outline" hideRequiredMarker>
        <mat-label>Vanaf</mat-label>
        <input matInput [matDatepicker]="fromPicker" [(ngModel)]="fromDate" name="fromDate">
        <mat-datepicker-toggle matIconSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" hideRequiredMarker>
        <mat-label>Tot</mat-label>
        <input matInput [matDatepicker]="toPicker" [(ngModel)]="toDate" name="toDate">
        <mat-datepicker-toggle matIconSuffix [for]="toPicker"></mat-datepicker-toggle>
        <mat-datepicker #toPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" hideRequiredMarker>
        <mat-label>Verantwoord</mat-label>
        <mat-select [(ngModel)]="paymentLinked">
          <mat-option [value]="true">Ja</mat-option>
          <mat-option [value]="false">Nee</mat-option>
          <mat-option [value]="undefined">Alles</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="filter()">Filter</button>
    </div>
  </div>

  <div class="invoices-list">
    <table mat-table [dataSource]="bankTransactions()" multiTemplateDataRows class="mat-elevation-z8">

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Datum</th>
        <td mat-cell *matCellDef="let element">{{ element.date | date:'dd/MM/yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="counterPartyName">
        <th mat-header-cell *matHeaderCellDef>Tegenpartij</th>
        <td mat-cell *matCellDef="let element">{{ element.counterPartyName }}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Bedrag</th>
        <td mat-cell *matCellDef="let element">{{ element.amount | currency:'EUR':'symbol':'1.2-2' }}</td>
      </ng-container>

      <ng-container matColumnDef="justified">
        <th mat-header-cell *matHeaderCellDef>Verantwoord</th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox [checked]="element.justified" disabled></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="bank-transaction-detail-wrapper" [class.bank-transaction-detail-wrapper-expanded]="isExpanded(element)">
            <div class="bank-transaction-detail">
              <h2>Details</h2>
              <div class="transaction-detail">
                <div class="transaction-detail-item">
                  <strong>Omschrijving:</strong> {{ element.statement }}
                </div>
              </div>
              @if ((bankTransactionDetails()?.purchaseInvoices ?? []).length > 0) {
                <h2>Purchase Invoices</h2>
                <mat-list>
                  @for (purchaseInvoice of bankTransactionDetails()?.purchaseInvoices; track purchaseInvoice.id) {
                    <mat-list-item style="height: 8em;">
                      <div matLine><strong>Date:</strong> {{ purchaseInvoice.invoiceDate }}</div>
                      <div matLine><strong>Vendor:</strong> {{ purchaseInvoice.vendorName }}</div>
                      <div matLine><strong>Amount:</strong> {{ purchaseInvoice.amount | currency:'EUR':'symbol':'1.2-2' }}</div>
                      <button mat-raised-button color="warn"
                              (click)="removeLink(element.id, purchaseInvoice.id!)">
                        Verwijder link
                      </button>
                    </mat-list-item>
                  }
                </mat-list>
              } @else {
                <hr>
                <h2>Koppel een factuur</h2>
                <p>Zoek naar facturen:</p>
                <div class="invoice-search-form">
                  <mat-form-field (click)="searchInvoices()" appearance="outline" class="invoice-search-field">
                    <mat-label>Search invoices</mat-label>
                    <input type="text" matInput [formControl]="invoiceCtrl" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayInvoice">
                      @for (invoice of this.filteredInvoices; track invoice.id) {
                        <mat-option [value]="invoice">
                          {{ invoice.vendorName }} - {{ invoice.id|| 'No number' }} - {{ invoice.amount | currency:'EUR':'symbol':'1.2-2' }}
                        </mat-option>
                      }
                    </mat-autocomplete>
                  </mat-form-field>
                  <button mat-raised-button color="primary" [disabled]="!invoiceCtrl.value"
                          (click)="linkInvoice(element.id, invoiceCtrl.value)">
                    Link Invoice
                  </button>
                </div>
                <hr>
                <app-mark-transaction (expenseTypeSubmitted)="updateTransactionWithExpenseType($event)"></app-mark-transaction>
              }
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
          class="transaction-row"
          [class.example-expanded-row]="isExpanded(element)"
          (click)="toggle(element)">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    </table>
    <mat-paginator (page)="pageChanged($event)"
                   [length]="totalItems()"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"
                   aria-label="Select page">
    </mat-paginator>
  </div>
</div>
