export * from './bank.service';
import { BankService } from './bank.service';
export * from './customer.service';
import { CustomerService } from './customer.service';
export * from './customerInvoice.service';
import { CustomerInvoiceService } from './customerInvoice.service';
export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './export.service';
import { ExportService } from './export.service';
export * from './invoiceDocument.service';
import { InvoiceDocumentService } from './invoiceDocument.service';
export * from './purchaseInvoice.service';
import { PurchaseInvoiceService } from './purchaseInvoice.service';
export * from './salesInvoice.service';
import { SalesInvoiceService } from './salesInvoice.service';
export * from './vendor.service';
import { VendorService } from './vendor.service';
export * from './webHook.service';
import { WebHookService } from './webHook.service';
export const APIS = [BankService, CustomerService, CustomerInvoiceService, DashboardService, ExportService, InvoiceDocumentService, PurchaseInvoiceService, SalesInvoiceService, VendorService, WebHookService];
