import {PurchaseInvoiceService} from "../../clients";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class PurchaseInvoicesService {

  constructor(private purchaseInvoices: PurchaseInvoiceService) {
  }

  getPurchaseInvoices(pageNumber: number, pageSize: number) {
    return this.purchaseInvoices.apiPurchasesInvoicesGet(pageNumber, pageSize, 'response', false);
  }

  uploadNewPurchaseInvoice(file: Blob) {
    return this.purchaseInvoices.apiPurchasesInvoicesDocumentPostForm(file);
  }

  removePurchaseInvoice(purchaseInvoiceId: number) {
    return this.purchaseInvoices.apiPurchasesInvoicesInvoiceIdDelete(purchaseInvoiceId);
  }

  getPurchaseInvoicePDF(id: number) {
    this.purchaseInvoices.apiPurchasesInvoicesIdDocumentGet(id).subscribe((data: Blob) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
}
