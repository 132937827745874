import { Component } from '@angular/core';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {SalesInvoicesService} from "../../serivces/sales-invoices.service";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatCardModule} from "@angular/material/card";
import {DecimalPipe} from "@angular/common";

@Component({
  selector: 'app-view-sales-invoice',
  templateUrl: './view-sales-invoice.component.html',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    RouterLink,
    MatButtonToggleModule,
    MatCardModule,
    DecimalPipe
  ],
  styleUrl: './view-sales-invoice.component.scss'
})
export class ViewSalesInvoiceComponent {

  private salesInvoiceId: number = 0;
  currentSalesInvoice$ = this.salesService.salesInvoice$;

  constructor(private salesService: SalesInvoicesService, private activatedRoute: ActivatedRoute) {
      this.activatedRoute.params.subscribe(({id}) => {
        this.salesInvoiceId = id;
        this.salesService.getSalesInvoice(this.salesInvoiceId)
      });
  }

  viewInvoice() {
    this.salesService.getSalesInvoicePDF(this.salesInvoiceId);
  }
}
