import {Component, effect} from '@angular/core';
import {CurrencyPipe, DatePipe} from "@angular/common";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {BankingService} from "../../serivces/banking.service";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from "@angular/material/table";
import {MatCheckbox} from "@angular/material/checkbox";
import {
  BlueBearInvoicingBusinessBankBankTransactionDetailBankTransactionPurchaseInvoice,
  BlueBearInvoicingBusinessBankUploadBankStatementBankTransaction
} from "../../../clients";
import {MatList, MatListItem} from "@angular/material/list";
import {MatLine, MatOption, provideNativeDateAdapter} from "@angular/material/core";
import {MatButton} from "@angular/material/button";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatSelect} from "@angular/material/select";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIcon} from '@angular/material/icon';
import {ActivatedRoute, Router} from '@angular/router';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {PurchaseInvoicesService} from "../../serivces/purchase-invoices.service";
import {MarkTransactionComponent} from "./mark-transaction/mark-transaction.component";

@Component({
  selector: 'app-bank-transactions',
  standalone: true,
  imports: [
    MatPaginator,
    CurrencyPipe,
    DatePipe,
    MatCell,
    MatCellDef,
    MatCheckbox,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatHeaderCellDef,
    MatList,
    MatListItem,
    MatLine,
    MatButton,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    MatSuffix,
    ReactiveFormsModule,
    FormsModule,
    MatIcon,
    MatAutocompleteModule,
    MarkTransactionComponent
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './bank-transactions.component.html',
  styleUrl: './bank-transactions.component.scss'
})
export class BankTransactionsComponent {
  bankTransactions = this.bankingService.transactions$;
  totalItems = this.bankingService.transactionsTotalItems$;
  bankTransactionDetails = this.bankingService.transactionDetails$;
  columnsToDisplay: string[] = ['date', 'counterPartyName', 'amount', 'justified'];
  expandedElement: BlueBearInvoicingBusinessBankUploadBankStatementBankTransaction | null = null;
  fromDate: Date | undefined = undefined;
  toDate: Date | undefined = undefined;
  paymentLinked: boolean | undefined = undefined;
  pageNumber = 1;
  pageSize = 10;
  selectedFile: File | null = null;
  isDragOver = false;
  isUploading = false;
  showUploadSection = false;
  invoiceCtrl = new FormControl('');
  filteredInvoices: BlueBearInvoicingBusinessBankBankTransactionDetailBankTransactionPurchaseInvoice[] = [];

  constructor(private readonly bankingService: BankingService,
              private router: Router,
              private route: ActivatedRoute,
              private purchaseInvoiceService: PurchaseInvoicesService) {
    // Read filters from URL when component initializes
    this.route.queryParams.subscribe(params => {
      // Parse query parameters
      this.fromDate = params['fromDate'] ? new Date(params['fromDate']) : undefined;
      this.toDate = params['toDate'] ? new Date(params['toDate']) : undefined;

      if (params['paymentLinked'] !== undefined) {
        if (params['paymentLinked'] === 'true') this.paymentLinked = true;
        else if (params['paymentLinked'] === 'false') this.paymentLinked = false;
        else this.paymentLinked = undefined;
      }

      this.pageNumber = params['page'] ? parseInt(params['page']) : 1;
      this.pageSize = params['pageSize'] ? parseInt(params['pageSize']) : 10;

      effect(() => {
        this.filteredInvoices = this.purchaseInvoiceService.purchaseInvoices$();
      });

      // Load transactions with these filters
      this.bankingService.getTransactions(
        this.pageNumber,
        this.pageSize,
        this.fromDate,
        this.toDate,
        this.paymentLinked
      );
    });
  }


  searchInvoices() {
    let transaction = this.bankTransactionDetails();
    if (!transaction) return;
    // get a date from the transaction date
    let transactionDate = this.parseDateString(transaction?.date!);
    this.purchaseInvoiceService.getPurchaseInvoices(1, 10, undefined, this.addDays(transactionDate, -15).toDateOnlyString(), this.addDays(transactionDate, 2).toDateOnlyString(), Math.abs(transaction!.amount! * 0.8), Math.abs(transaction!.amount! * 1.2), false);
    this.filteredInvoices = this.purchaseInvoiceService.purchaseInvoices$();
    console.log(this.filteredInvoices);
  }

  displayInvoice(invoice: any): string {
    return invoice ? `${invoice.vendorName} - ${invoice.amount}` : '';
  }

  linkInvoice(transactionId: number, invoice: any) {
    if (!invoice) return;

    this.bankingService.linkTransaction(transactionId, invoice.id).subscribe({
      next: () => {
        // Refresh the transaction details
        this.bankingService.loadTransactionDetails(transactionId);
        this.invoiceCtrl.reset();
        this.refreshBankTransactions();
      },
      error: (err) => console.error('Error linking invoice:', err)
    });
  }

  refreshBankTransactions() {
    this.bankingService.getTransactions(
      this.pageNumber,
      this.pageSize,
      this.fromDate,
      this.toDate,
      this.paymentLinked
    );
  }

  parseDateString(dateString: string): Date {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  pageChanged($event: PageEvent) {
    this.pageNumber = $event.pageIndex + 1;
    this.pageSize = $event.pageSize;

    // Update URL with new page parameters
    const queryParams = {
      page: this.pageNumber.toString(),
      pageSize: this.pageSize.toString()
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });

    this.refreshBankTransactions();
  }

  isExpanded(element: BlueBearInvoicingBusinessBankUploadBankStatementBankTransaction) {
    return this.expandedElement === element;
  }

  toggle(bankTransaction: BlueBearInvoicingBusinessBankUploadBankStatementBankTransaction) {
    if (!this.isExpanded(bankTransaction)) {
      this.bankingService.loadTransactionDetails(bankTransaction.id!);
    }
    this.expandedElement = this.isExpanded(bankTransaction) ? null : bankTransaction;
  }

  filter() {
    this.pageNumber = 1; // Reset to first page when filtering

    // Update URL with filter parameters
    const queryParams: any = {};

    if (this.fromDate) queryParams.fromDate = this.fromDate.toISOString().split('T')[0];
    if (this.toDate) queryParams.toDate = this.toDate.toISOString().split('T')[0];
    if (this.paymentLinked !== undefined) queryParams.paymentLinked = this.paymentLinked.toString();
    queryParams.page = this.pageNumber;
    queryParams.pageSize = this.pageSize;

    // Navigate with the new query params (without reloading the page)
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge', // Merge with existing params
    });

    // Load transactions with the filters
    this.refreshBankTransactions();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;

    if (event.dataTransfer?.files.length) {
      this.selectedFile = event.dataTransfer.files[0];
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      this.selectedFile = input.files[0];
    }
  }

  clearSelectedFile() {
    this.selectedFile = null;
  }

  uploadFile() {
    if (!this.selectedFile) {
      return;
    }

    // Show loading state
    this.isUploading = true;

    this.bankingService.uploadBankStatement(this.selectedFile).subscribe({
      next: (response) => {
        console.log('Upload successful', response);
        this.selectedFile = null;
        this.isUploading = false;
        // Refresh transactions
        this.filter();
      },
      error: (error) => {
        console.error('Upload failed', error);
        this.isUploading = false;
        // Handle error (show error message)
      }
    });
  }

  toggleUploadSection() {
    this.showUploadSection = !this.showUploadSection;
    // Reset selected file when hiding
    if (!this.showUploadSection) {
      this.clearSelectedFile();
    }
  }

  removeLink(transactionId: number, purchaseInvoiceId: number) {
    this.bankingService.removeLink(transactionId, purchaseInvoiceId).subscribe({
      next: () => {
        // Refresh the transaction details
        this.bankingService.loadTransactionDetails(transactionId);
        this.refreshBankTransactions();
      },
      error: (err) => console.error('Error removing link:', err)
    });
  }

  updateTransactionWithExpenseType($event: number) {
    this.bankingService.updateTransactionWithExpenseType(this.expandedElement!.id!, $event).subscribe({
      next: () => {
        console.log('Update transaction with ExpenseType', $event);
        this.refreshBankTransactions();
      },
      error: (err) => console.error('Error updating transaction with expense type:', err)
    });
  }
}
