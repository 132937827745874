import { AuthService } from "@auth0/auth0-angular";
import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import {environment} from "../../environments/environment";

@Injectable({providedIn: 'root'})
export class LoginService {

  constructor(private readonly auth: AuthService) {}

  login() {
    this.auth.loginWithRedirect({
      appState: {
        target: '/home',
      },
      authorizationParams: {
        audience: environment.auth.audience,
        scope: 'api offline_access'
      }
    });
  }

  logout() {
    this.auth.logout();
  }

  public getAccessToken(): Observable<string> {
    return from(this.auth.getAccessTokenSilently({
      authorizationParams: {
        audience: environment.auth.audience,
        scope: 'api offline_access'
      }
    })).pipe(map(token => token));
  }

  isLoggedIn() {
    return this.auth.isAuthenticated$;
  }
}
