import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {LoginService} from "./login.service";
import {Injectable} from "@angular/core";
import {Observable, switchMap} from "rxjs";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private readonly loginService: LoginService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.shouldAddAccessToken(req))
      return next.handle(req);
    else {
      return this.loginService.getAccessToken().pipe(
        switchMap(token => {
          req = req.clone({headers: req.headers.set('Authorization', `Bearer ${token}`)});
          return next.handle(req);
        })
      );
    }
  }

  shouldAddAccessToken(req: HttpRequest<any>): boolean {
    return req.url.indexOf("bearfinance-api") > 0 || req.url.indexOf("localhost") > 0;
  }
}
