import { Component } from '@angular/core';
import {MatToolbar} from "@angular/material/toolbar";
import {MatIcon} from "@angular/material/icon";
import {RouterLink, RouterOutlet} from "@angular/router";
import {LoginService} from "../../serivces/login.service";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {SpinnerComponent} from "../spinner/spinner.component";
import {SpinnerService} from "../../serivces/spinner.service";
import {AsyncPipe, NgIf} from "@angular/common";

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    MatToolbar,
    MatIcon,
    RouterOutlet,
    MatButton,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    RouterLink,
    MatMenuTrigger,
    SpinnerComponent,
    AsyncPipe,
    NgIf
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {

  isLoading$ = this.spinnerService.isLoading$;


  constructor(private readonly loginService: LoginService, private spinnerService: SpinnerService) {}

  logout() {
    this.loginService.logout();
  }
}
