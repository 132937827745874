<div class="container">
  <!-- button to upload new purchase invoice -->
  <button class="upload-button" mat-raised-button color="primary" (click)="showUploadInvoice = !showUploadInvoice">
    Upload new purchase invoice
  </button>

  @if (this.showUploadInvoice) {
    <div class="upload-form">
      <div class="dropzone d-flex flex-column justify-content-center align-items-center" appDragAndDrop
           (fileDropped)="onFileChange($event)">
        <input (change)="onFileSelected($event)" type="file" multiple id="fileDropRef"/>
        <i class="fa-solid fa-cloud-arrow-up text-primary"></i>
        <h3>Sleep hier bestanden om ze te kunnen uploaden</h3>
        <h3>of</h3>
        <label for="fileDropRef">Selecteer bestanden</label>
        <div>
          @for (file of files; track file.name) {
            <div class="d-flex">
              <span>
                  <i class="fa-solid fa-file"></i>
                {{ file.name }}
              </span>
            </div>
          }
        </div>
      </div>
      <button mat-raised-button color="primary" (click)="uploadPurchaseInvoices()">Upload</button>
    </div>
  }

  <mat-accordion>
    @for (currentSalesInvoice of currentPurchasesInvoices(); track currentSalesInvoice.id) {
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            @if (currentSalesInvoice.invoiceDate) {
              {{ currentSalesInvoice.invoiceDate }} {{ currentSalesInvoice.vendorName }} (€{{ currentSalesInvoice.grandTotal | number : '1.2-2' }})
            } @else {
              Laden...
            }
          </mat-panel-title>
          <mat-panel-description>
            @if (currentSalesInvoice.linked) {
              <mat-icon aria-hidden="false" class="green-icon" fontIcon="link"></mat-icon>
            } @else {
              <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="link" color="accent"></mat-icon>
            }
          </mat-panel-description>
        </mat-expansion-panel-header>
        Factuur totaal : € {{ currentSalesInvoice.grandTotal | number : '1.2-2' }} <br/>
        <button mat-raised-button color="primary" (click)="viewInvoice(currentSalesInvoice.id!)">Bekijk factuur</button>
        <button mat-raised-button color="warn" (click)="removePurchaseInvoice(currentSalesInvoice.id!)">Verwijder
          factuur
        </button>
      </mat-expansion-panel>
    }
  </mat-accordion>
  <mat-paginator (page)="pageChanged($event)"
                 [length]="totalItems"
                 [pageSize]="10"
                 [pageSizeOptions]="[5, 10, 25, 100]"
                 aria-label="Select page">
  </mat-paginator>
</div>
