<mat-toolbar>
  <button mat-button [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/home">Home</button>
    <button mat-menu-item routerLink="/sales-invoices">Sales invoices</button>
    <button mat-menu-item routerLink="/create-sales-invoices">Create invoices</button>
    <button mat-menu-item routerLink="/purchase-invoices">Purchase invoices</button>
  </mat-menu>
  <span>Bear Finance - sales invoices</span>
  <span class="example-spacer"></span>
  <button mat-icon-button color="accent" aria-label="Example icon button with a bookmark icon">
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>

<div class="container">
  <mat-accordion>
    @for (currentSalesInvoice of currentSalesInvoices$(); track currentSalesInvoice.invoiceId) {
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ currentSalesInvoice.invoiceNumber }} (€{{currentSalesInvoice.invoiceGrandTotal | number : '1.2-2'}})
          </mat-panel-title>
          <mat-panel-description>
            {{ currentSalesInvoice.invoiceDate }} - {{ currentSalesInvoice.customerName }}
          </mat-panel-description>
        </mat-expansion-panel-header>
          Netto : €{{currentSalesInvoice.invoiceTotal | number : '1.2-2' }} <br />
          Brutto : €{{currentSalesInvoice.invoiceGrandTotal | number : '1.2-2'}} <br />
          BTW : €{{currentSalesInvoice.invoiceVAT | number : '1.2-2'}} <br />
          <button mat-raised-button color="primary" routerLink="/sales-invoices/{{currentSalesInvoice.invoiceId}}">Bekijk factuur</button>
      </mat-expansion-panel>
    }
  </mat-accordion>
</div>
