import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {

  @Output() fileDropped = new EventEmitter<FileList>();

  @HostBinding('class.fileover') fileOver: boolean = false;

  @HostListener('dragover', ['$event']) onDragOver(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragLeave', ['$event']) onDragLeave(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event']) onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = false;

    if (evt.dataTransfer) {
      let files = evt.dataTransfer.files;
      this.fileDropped.emit(files);
    }
  }


  constructor() {
  }

}
