<mat-toolbar>
  <button mat-button [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/home">Home</button>
    <button mat-menu-item routerLink="/sales-invoices">Sales invoices</button>
    <button mat-menu-item routerLink="/create-sales-invoices">Create invoices</button>
    <button mat-menu-item routerLink="/purchase-invoices">Purchase invoices</button>
  </mat-menu>
  <span>Bear Finance</span>
  <span class="example-spacer"></span>
  <button (click)="logout()" mat-icon-button color="accent" aria-label="Example icon button with a bookmark icon">
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>
<div class="container">
  <form [formGroup]="createSalesInvoiceFormGroup">
    <h2>Factuur gegevens</h2>

    <label for="customer">Klant op factuur</label>
    <p-autoComplete
      inputStyleClass="width-100"
      id="customer"
      class="input-form"
      [dropdown]="true"
      formControlName="customer"
      [suggestions]="filteredSuggestions"
      (completeMethod)="search($event)"
      optionLabel="name"/>

    <label for="date">Factuur datum</label>
    <p-calendar
      [showIcon]="true"
      [showOnFocus]="false"
      dateFormat="dd/mm/yy"
      class="input-form"
      id="date"
      formControlName="date" />


    <h2>Items op factuur</h2>

    <div formArrayName="items">
      @for (item of items.controls; track item; let idx = $index) {
        <hr>
        <div [formGroupName]="idx">
          <div class="items-form">
            <label for="description">Lijn item naam</label>
            <input
              class="input-form"
              id="description"
              formControlName="description"
              type="text"
              pInputText />
          </div>
          <div class="items-form">
            <label for="count">Lijn aantal</label>
            <input
              class="input-form"
              id="count"
              formControlName="count"
              type="number"
              pInputText />
          </div>
          <div class="items-form">
            <label for="price">Eenheid prijs</label>
            <input
              class="input-form"
              id="price"
              formControlName="price"
              type="number"
              pInputText />
          </div>
          @if(items.length > 1) {
            <p-button (click)="removeItem(idx)" icon="pi pi-trash" severity="danger" />
          }
        </div>
      }
    </div>
    <hr>
    <p-button icon="pi pi-plus" (click)="addItem()" label="Nieuwe lijn" severity="info"/>
    <hr>
    <p-button icon="pi pi-receipt" (click)="createInvoice()" label="Factuur maken" severity="success" />

  </form>
</div>
