import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatOption} from "@angular/material/autocomplete";
import {MatFormField, MatLabel, MatSelect} from "@angular/material/select";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-mark-transaction',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatOption,
    MatSelect,
    MatLabel,
    MatFormField,
    MatButton
  ],
  templateUrl: './mark-transaction.component.html',
  styleUrl: './mark-transaction.component.scss'
})
export class MarkTransactionComponent {

  @Output() expenseTypeSubmitted = new EventEmitter<number>();

  transactionForm = new FormGroup({
    expenseType: new FormControl(0, Validators.required),
  });

  expenseTypes = [
    {value: 1, viewValue: 'Factuur (KOPPEL EEN FACTUUR!)'},
    {value: 2, viewValue: 'Persoonlijke uitgave (verworpen)'},
    {value: 3, viewValue: 'Financiele kost (bank kosten)'},
    {value: 4, viewValue: 'Belastingen'}
  ];

  onSubmit() {
    if (this.transactionForm.value.expenseType) {
      this.expenseTypeSubmitted.emit(this.transactionForm.value.expenseType);
    }
  }
}
