<p>
  <mat-toolbar>
    <span>Bear finance</span>
    <span class="example-spacer"></span>
  </mat-toolbar>
</p>
<div class="d-flex justify-content-center align-items-center">
  <div class="d-grid">
    <button mat-flat-button color="primary" (click)="login()">Aanmelden</button>
    <button mat-flat-button color="primary" (click)="logout()">Afmelden</button>
  </div>
</div>
