<mat-toolbar>
  <button mat-button [matMenuTriggerFor]="menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/home">Home</button>
    <button mat-menu-item routerLink="/sales-invoices">Sales invoices</button>
    <button mat-menu-item routerLink="/create-sales-invoices">Create invoices</button>
    <button mat-menu-item routerLink="/purchase-invoices">Purchase invoices</button>
  </mat-menu>
  <span>Bear Finance</span>
  <span class="example-spacer"></span>
  <button (click)="logout()" mat-icon-button color="accent" aria-label="Example icon button with a bookmark icon">
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>
<div class="container">
  <app-spinner></app-spinner>
  <div *ngIf="!(isLoading$ | async)">
    <router-outlet></router-outlet>
  </div>
</div>
