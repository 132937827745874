<div class="container">
  <div>
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          {{currentSalesInvoice$()!.invoiceYear}}/{{currentSalesInvoice$()!.invoiceNumber}} (€{{currentSalesInvoice$()!.grandTotal | number : '1.2-2'}})
        </mat-card-title>
        <mat-card-subtitle>
          {{currentSalesInvoice$()!.invoiceDate}} - {{currentSalesInvoice$()!.customer!.name}}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <h2>Factuur bedrag</h2>
        Netto : €{{currentSalesInvoice$()!.subTotal | number : '1.2-2' }} <br />
        Brutto : €{{currentSalesInvoice$()!.grandTotal | number : '1.2-2'}} <br />
        BTW : €{{currentSalesInvoice$()!.vat | number : '1.2-2'}} <br />
        <h2>Items op factuur</h2>
        <hr>
        @for (item of currentSalesInvoice$()!.items; track item; let idx = $index) {
          <div>
            <h4>{{item.description}}</h4>
            <p>{{item.count}} x €{{item.price | number : '1.2-2'}}</p>
          </div>
          <hr>
        }
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="this.viewInvoice()">Bekijk factuur</button>
      </mat-card-actions>
    </mat-card>
</div>
