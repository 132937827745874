<div class="inner-container">
  <mat-card class="invoice-card">
    <mat-card-header>
      <mat-card-title>
        <div class="header-content">
          <div class="left-header">
            <h1>{{ currentPurchaseInvoice$()?.vendor?.name }}</h1>
          </div>
          <div class="right-header">
            <span class="invoice-number">{{ currentPurchaseInvoice$()?.invoiceId }}</span>
          </div>
        </div>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="invoice-details">
        <div class="detail-row">
          <mat-form-field>
            <mat-label>Invoice Date</mat-label>
            <input matInput [matDatepicker]="picker" [value]="currentPurchaseInvoice$()?.invoiceDate">
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Due Date</mat-label>
            <input matInput [matDatepicker]="duePicker" [value]="currentPurchaseInvoice$()?.invoiceDate">
            <mat-datepicker-toggle matSuffix [for]="duePicker"></mat-datepicker-toggle>
            <mat-datepicker #duePicker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="total-row">
          <span>Excl BTW</span>
          <mat-form-field>
            <input matInput type="number" [(ngModel)]="currentPurchaseInvoice$()!.netAmount">
          </mat-form-field>
        </div>
        <div class="total-row">
          <span>BTW</span>
          <mat-form-field>
            <input matInput type="number" [(ngModel)]="currentPurchaseInvoice$()!.vatAmount">
          </mat-form-field>
        </div>
        <div class="total-row grand-total">
          <span>Total</span>
          <mat-form-field [class.invalid-total]="!isGrossAmountValid()">
            <input matInput type="number" [(ngModel)]="currentPurchaseInvoice$()!.grossAmount">
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>

    <mat-card-actions align="end">
      <button mat-button (click)="viewInvoice(currentPurchaseInvoice$()!.id!)">VIEW PDF</button>
      <button mat-raised-button color="primary" (click)="saveChanges()">SAVE CHANGES</button>
    </mat-card-actions>
  </mat-card>
</div>
