/**
 * BlueBear.Invoicing.WebAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BlueBearInvoicingBusinessInvoicesInvoicesPurchasesVendor } from './blueBearInvoicingBusinessInvoicesInvoicesPurchasesVendor';
import { BlueBearInvoicingBusinessInvoicesTransactionBankTransaction } from './blueBearInvoicingBusinessInvoicesTransactionBankTransaction';

export interface BlueBearInvoicingBusinessInvoicesInvoicesPurchasesPurchaseInvoice { 
    id?: number;
    invoiceId?: string;
    approved?: boolean;
    paid?: boolean;
    vendor?: BlueBearInvoicingBusinessInvoicesInvoicesPurchasesVendor;
    invoiceDate?: string;
    expirationDate?: string;
    netAmount?: number;
    vatAmount?: number;
    grossAmount?: number;
    approvedOn?: Date;
    possibleDuplicateId?: number;
    linkedTransactions?: Array<BlueBearInvoicingBusinessInvoicesTransactionBankTransaction>;
}