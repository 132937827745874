<form [formGroup]="transactionForm" (ngSubmit)="onSubmit()">
  <h2>Of selecteer een ander uitgave type</h2>
  <mat-form-field appearance="outline">
    <mat-label>Type uitgave</mat-label>
    <mat-select formControlName="expenseType">
      <mat-option [value]="null">None</mat-option>
      @for (type of expenseTypes; track type) {
        <mat-option [value]="type.value">{{ type.viewValue }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <button mat-raised-button color="primary" type="submit" [disabled]="!transactionForm.valid">Type opslaan</button>
</form>
