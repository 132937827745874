import {AuthService} from "@auth0/auth0-angular";
import {Injectable} from "@angular/core";
import {map} from "rxjs";

@Injectable({providedIn: 'root'})
export class LoginService {

  constructor(private auth: AuthService) {}

  login() {
    this.auth.loginWithRedirect({
      appState: {
        target: '/home',
      },
    });
  }

  logout() {
    this.auth.logout();
  }

  public GetAccessToken() {  return this.auth.getAccessTokenSilently().pipe(map(token => token)); }

  isLoggedIn() {
    return this.auth.isAuthenticated$;
  }
}
